(function($) {

  function Modal(element) {
    this.$el = $(element);
  }

  Modal.prototype = {
    init: function() {
      this.$el.each(function() {

        var currentModal = $(this);

        //click next
        currentModal.find('.js-modal-next').click(function() {
          currentModal.modal('hide');
          currentModal.closest(".js-modal").nextAll(".js-modal").first().modal('show');
        });

        //click prev 
        currentModal.find('.js-modal-prev').click(function() {
          currentModal.modal('hide');
          currentModal.closest(".js-modal").prevAll(".js-modal").first().modal('show');
        });

      });
    }
  };

  var modal = new Modal('.js-modal');
  modal.init();



})(jQuery);
