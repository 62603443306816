(function($) {
    function Target(element) {
        this.$el = $(element);
        this.$body = $('html,body');
    }

    Target.prototype = {
        init: function() {
            var t = this;
            var id, target;
            this.$el.on('click', function(event) {
                event.preventDefault();

                if ( $(this).is( "a" ) ) {
                    id = $(this).attr("href");
                    target = $(id).offset().top;

                    t.$body.animate({ scrollTop: target }, 500);
                }else {
                    id = $(this).find('a').attr("href");
                    target = $(id).offset().top;

                    t.$body.animate({ scrollTop: target }, 500);
                }

            });
        } 
    };

    if ( $( ".js-target" ).length ) {
        var target = new Target('.js-target');
        target.init();
    }

})(jQuery);