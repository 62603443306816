(function($) {
  function Menu(element) {
    this.$el = $(element);
    this.$body = $('body');
  }

  Menu.prototype = {
    init: function() {
      var t = this;
      var $type, $target;

      // Detect click event on button
      this.$el.each(function() {
        $(this).on('click', function() {
          $type = $(this).data('toggle');
          $target = $($(this).data('target'));

          t.$body.toggleClass($type + '-open menu-open');
          $target.toggleClass('in');

          return false;
        });
      });

      // Detect outside the container was clicked
      this.$body.on('click', function(event) {
        if ($(this).hasClass($type + '-open')) {
          if ($(event.target).hasClass($target[0])) {
            return false;
          } else if ($(event.target).hasClass('dropdown-toggle')) {
            return true;
          }

          t.$body.removeClass($type + '-open menu-open');
          $target.removeClass('in');
        }
      });
    }
  };

  if ($(".navbar-toggle").length) {
    var menu = new Menu('.navbar-toggle');
    menu.init();
  }
})(jQuery);
