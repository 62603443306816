(function($) {

  function Header(element) {
    this.$el = $(element);
  }

  Header.prototype = {
    init: function() {
      this.$window = $(window); 
      this.$document = $(document);
      this.$body = $('body');

      this.IS_VISIBLE = 'is-visible';
      this.HEADER_VISIBLE = 'header-visible';

      this.didScroll = false;
      this.lastScrollTop = 0;
      this.delta = 10;
      this.headerHeight = this.$el.outerHeight();


      this.set();
    },
    set: function() {
      var t = this;

      this.$window.on('scroll', function() {
        t.didScroll = true;
      });

      setInterval(function() {
        if (t.didScroll) {
          t.scroll();
          t.didScroll = false;
        }
      }, 200);

    },
    hidden: function() {
      this.$el.removeClass(this.IS_VISIBLE);
      this.$body.removeClass(this.NAVBAR_VISIBLE);
    },
    visible: function() {
      this.$el.addClass(this.IS_VISIBLE);
      this.$body.addClass(this.NAVBAR_VISIBLE);
    },
    reset: function() {
      this.$el.removeClass(this.IS_VISIBLE);
      this.$body.removeClass(this.NAVBAR_VISIBLE);
    },
    scroll: function() {
      var st = this.$window.scrollTop();

      // Scroll more than delta
      if (Math.abs(this.lastScrollTop - st) <= this.delta) {
        return;
      }

      if (st > this.lastScrollTop && st > this.headerHeight) {
        this.hidden();
      } else if (st + this.$window.height() < this.$document.height()) {
        this.visible();
      }

      if (st < 500) {
        this.reset();
      }


      this.lastScrollTop = st;
    }
  };

  if ($('.js-header').length) {
    var header = new Header('.js-header');
    header.init();
  }
})(jQuery);
