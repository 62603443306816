(function($) {
  $('.js-slider-content').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: 'linear',  
    asNavFor: '.js-slider-img'
  });
  $('.js-slider-img').slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    asNavFor: '.js-slider-content',
  });
  
})(jQuery);
 